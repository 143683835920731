import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Banner } from 'src/app/services/banner/banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit, OnDestroy {
  @Input() banner: Observable<Banner>;
  bannerType = 'info';
  showIcon = true;
  iconColor = '#000000';
  showBanner = false;
  captionHeader = 'An error occurred';
  captionBody = ['Something has happened'];
  subscription: Subscription = new Subscription();
  constructor() {}

  //Cast ScanFix(NCS) for rule type: Always unsubscribe events in Angular onDestroy method
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.banner.subscribe((banner) => {
      try {
        this.showBanner = banner.show;
        this.showIcon = banner.showIcon;
        this.captionHeader = banner.details?.header ?? '';
        this.captionBody = banner.details?.body ?? [''];
        this.bannerType = banner.type;
        this.iconColor = this.getIconColor(banner.type);
      } catch {
        this.showBanner = false;
      }
    });
  }
  getIconColor(type) {
    return type === 'info' ? '#000000' : '#FFFFFF';
  }
}
