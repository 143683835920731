/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import t from "../../core/Collection.js";
import s from "../../core/Evented.js";
import { watch as i, initial as r } from "../../core/reactiveUtils.js";
import { property as a } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as o } from "../../core/accessorSupport/decorators/subclass.js";
import { isLinkChartView as l } from "../../linkChart/utils.js";
import n from "./ListItem.js";
import { getItemLayers as d, findLayerListMode as h } from "./support/layerListUtils.js";
const m = {
    view: "view",
    viewLayers: "view-layers",
    mapLayers: "map-layers",
    layerViews: "layer-views",
    layerListMode: "layer-list-mode"
  },
  c = "hide",
  p = t.ofType(n);
let y = class extends s.EventedAccessor {
  constructor(e) {
    super(e), this.checkPublishStatusEnabled = !1, this.listItemCreatedFunction = null, this.listModeDisabled = !1, this.operationalItems = new p(), this.view = null;
  }
  initialize() {
    this.addHandles([i(() => !0 === this.view?.ready, () => this._viewHandles(), r), i(() => [this.listItemCreatedFunction, this.checkPublishStatusEnabled, this.listModeDisabled], () => this._recompileList()), i(() => l(this.view) ? this.view.inGeographicLayout : null, () => this._compileList())], m.view);
  }
  destroy() {
    this._removeAllItems(), this.view = null;
  }
  get state() {
    const {
      view: e
    } = this;
    return e?.ready ? "ready" : e ? "loading" : "disabled";
  }
  get totalItems() {
    return this.operationalItems.flatten(e => e.children).length;
  }
  triggerAction(e, t) {
    e && !e.disabled && this.emit("trigger-action", {
      action: e,
      item: t
    });
  }
  moveListItem(e, t, s, i) {
    const r = e?.layer;
    if (!r || "subtype-sublayer" === r.type || "sublayer" === r.type) return;
    const a = this.view?.map?.layers,
      o = t ? d(t) : a,
      l = s ? d(s) : a;
    if (!o || !l) return;
    const {
        operationalItems: n
      } = this,
      h = t?.children || n,
      m = s?.children || n,
      c = l.length - i;
    e.parent = s || null, h.remove(e), o.remove(r), m.includes(e) || m.add(e, c), l.includes(r) || l.add(r, c), this._compileList();
  }
  _createLayerViewHandles(e) {
    this.removeHandles(m.layerViews), this._compileList(), e && this.addHandles(e.on("change", () => this._compileList()), m.layerViews);
  }
  _createMapLayerHandles(e) {
    this.removeHandles(m.mapLayers), this._compileList(), e && this.addHandles(e.on("change", () => this._compileList()), m.mapLayers);
  }
  _createListItem(e) {
    const {
      view: t,
      listItemCreatedFunction: s,
      checkPublishStatusEnabled: i,
      listModeDisabled: r
    } = this;
    return new n({
      checkPublishStatusEnabled: i,
      listModeDisabled: r,
      layer: e,
      listItemCreatedFunction: s,
      view: t
    });
  }
  _removeAllItems() {
    this.operationalItems.destroyAll();
  }
  _getViewableLayers(e) {
    return e ? this.listModeDisabled ? e : e.filter(e => h(e) !== c) : void 0;
  }
  _watchLayersListMode(e) {
    this.removeHandles(m.layerListMode), e && !this.listModeDisabled && this.addHandles(i(() => e.filter(e => "listMode" in e).map(e => e.listMode).toArray(), () => this._compileList()), m.layerListMode);
  }
  _compileList() {
    const e = this.view?.map?.layers,
      t = l(this.view) && !this.view.inGeographicLayout ? e?.filter(({
        type: e
      }) => "link-chart" === e) : e;
    this._watchLayersListMode(t);
    const s = this._getViewableLayers(t);
    s?.length ? (this._createNewItems(s), this._removeItems(s), this._sortItems(s)) : this._removeAllItems();
  }
  _createNewItems(e) {
    const {
      operationalItems: t
    } = this;
    e.forEach(e => {
      t.some(t => t.layer === e) || t.add(this._createListItem(e));
    });
  }
  _removeItems(e) {
    const {
        operationalItems: t
      } = this,
      s = [];
    t.forEach(t => {
      t && e && e.includes(t.layer) || s.push(t);
    }), t.destroyMany(s);
  }
  _sortItems(e) {
    const {
      operationalItems: t
    } = this;
    t.sort((t, s) => {
      const i = e.indexOf(t.layer),
        r = e.indexOf(s.layer);
      return i > r ? -1 : i < r ? 1 : 0;
    });
  }
  _recompileList() {
    this._removeAllItems(), this._compileList();
  }
  _viewHandles() {
    const {
      view: e
    } = this;
    this.removeHandles([m.mapLayers, m.layerViews, m.viewLayers]), e?.ready ? this.addHandles([i(() => this.view?.map?.allLayers, e => this._createMapLayerHandles(e), r), i(() => this.view?.allLayerViews, e => this._createLayerViewHandles(e), r)], m.viewLayers) : this._removeAllItems();
  }
};
e([a()], y.prototype, "checkPublishStatusEnabled", void 0), e([a()], y.prototype, "listItemCreatedFunction", void 0), e([a({
  nonNullable: !0
})], y.prototype, "listModeDisabled", void 0), e([a({
  type: p
})], y.prototype, "operationalItems", void 0), e([a({
  readOnly: !0
})], y.prototype, "state", null), e([a()], y.prototype, "totalItems", null), e([a()], y.prototype, "view", void 0), y = e([o("esri.widgets.LayerList.LayerListViewModel")], y);
const v = y;
export { v as default };