/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const e = "esri-basemap-toggle",
  a = "esri-basemap-thumbnail",
  _ = {
    base: e,
    secondaryBasemapImage: `${e}__image--secondary`,
    container: `${a} ${e}__container`,
    image: `${a}__image ${e}__image`,
    imageLoading: `${e}__image--loading`,
    overlay: `${a}__overlay ${e}__image-overlay`,
    title: `${a}__title ${e}__title`,
    overlayScrim: `${a}__overlay-scrim`
  };
export { _ as css };