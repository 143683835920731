/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { IdentifiableMixin as t } from "../../core/Identifiable.js";
import { destroyMaybe as n } from "../../core/maybe.js";
import { watch as i, initial as r } from "../../core/reactiveUtils.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as o } from "../../core/accessorSupport/decorators/subclass.js";
import l from "../Widget.js";
import { isSublayer as d } from "./support/layerListUtils.js";
import { isWidget as a } from "../support/widget.js";
import { tsx as p } from "../support/jsxFactory.js";
let g = class extends t(l) {
  constructor(e, t) {
    super(e, t), this._legend = null, this.className = null, this.content = null, this.flowEnabled = !1, this.image = null, this.listItem = null, this.open = !1, this.visible = !0;
  }
  initialize() {
    this.addHandles([i(() => this._canCreateLegend, () => this._createLegend(), r), i(() => [this._legend, this._legendOptions], () => this._updateLegend(), r)]);
  }
  destroy() {
    this._legend = n(this._legend);
  }
  get _canCreateLegend() {
    const {
      content: e,
      listItem: t
    } = this;
    if (!t) return !1;
    const n = "legend";
    return e === n || null != e && !!Array.isArray(e) && e.includes(n);
  }
  get _legendOptions() {
    const {
        listItem: e,
        _legendLayerInfo: t
      } = this,
      n = e?.view;
    return t && n ? {
      view: n,
      layerInfos: [t]
    } : {};
  }
  get _legendLayerInfo() {
    const e = this.listItem?.layer;
    if (!e || "subtype-sublayer" === e.type) return null;
    const t = d(e) ? e : null,
      n = e?.parent,
      i = null != n && "type" in n && "map-image" === n.type ? t?.source : null,
      r = "";
    return i && n ? {
      layer: n,
      title: r,
      sublayerIds: [i.mapLayerId]
    } : {
      layer: e,
      title: r
    };
  }
  get disabled() {
    const {
      listItem: e,
      _legend: t,
      content: n
    } = this;
    return !e || !(Array.isArray(n) && n.length > 1) && !!t && (!t.activeLayerInfos?.length || !e.visibleAtCurrentScale || !e.visible);
  }
  set disabled(e) {
    this._overrideIfSome("disabled", e);
  }
  get icon() {
    const {
        image: e
      } = this,
      t = this._getFirstWidget();
    return this._get("icon") ?? (!e && t ? t.icon : null);
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get title() {
    return this._get("title") || (this._getFirstWidget()?.label ?? "");
  }
  set title(e) {
    this._override("title", e);
  }
  render() {
    return p("div", {
      class: "esri-list-item-panel"
    }, this._renderContents());
  }
  _renderContent(e) {
    const {
      _legend: t,
      disabled: n,
      open: i
    } = this;
    return e && !n && i ? "legend" === e && t ? p("div", {
      key: "legend-widget"
    }, t.render()) : "string" == typeof e ? p("div", {
      innerHTML: e,
      key: e
    }) : a(e) ? p("div", {
      key: "content-widget"
    }, e.render()) : e instanceof HTMLElement ? p("div", {
      afterCreate: this._attachToNode,
      bind: e,
      key: "content-element"
    }) : null : null;
  }
  _renderContents() {
    const {
      content: e,
      open: t
    } = this;
    return t ? Array.isArray(e) ? e.map(e => this._renderContent(e)) : this._renderContent(e) : null;
  }
  async _createLegend() {
    if (n(this._legend), this._legend = null, !this._canCreateLegend) return;
    const {
        default: e
      } = await import("../Legend.js"),
      t = new e(this._legendOptions);
    this._legend = t;
  }
  _attachToNode(e) {
    e.appendChild(this);
  }
  _updateLegend() {
    const e = this._legend;
    e && e.set(this._legendOptions);
  }
  _getWidget(e) {
    return "legend" === e ? this._legend : a(e) ? e : null;
  }
  _getFirstWidget() {
    const {
      content: e
    } = this;
    return Array.isArray(e) ? e.map(e => this._getWidget(e)).find(e => e) : this._getWidget(e);
  }
};
e([s()], g.prototype, "_legend", void 0), e([s()], g.prototype, "_canCreateLegend", null), e([s()], g.prototype, "_legendOptions", null), e([s()], g.prototype, "_legendLayerInfo", null), e([s()], g.prototype, "className", void 0), e([s()], g.prototype, "content", void 0), e([s()], g.prototype, "disabled", null), e([s()], g.prototype, "flowEnabled", void 0), e([s()], g.prototype, "icon", null), e([s()], g.prototype, "image", void 0), e([s()], g.prototype, "listItem", void 0), e([s()], g.prototype, "open", void 0), e([s()], g.prototype, "title", null), e([s()], g.prototype, "visible", void 0), g = e([o("esri.widgets.LayerList.ListItemPanel")], g);
const c = g;
export { c as default };