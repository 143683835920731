/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as r } from "../../core/accessorSupport/decorators/subclass.js";
let s = class extends t {
  constructor() {
    super(...arguments), this.catalogLayerList = !0, this.closeButton = !1, this.collapseButton = !1, this.errors = !1, this.filter = !1, this.flow = !0, this.heading = !1, this.statusIndicators = !0, this.temporaryLayerIndicators = !1;
  }
};
o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "catalogLayerList", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "closeButton", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "collapseButton", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "errors", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "filter", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "flow", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "heading", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "statusIndicators", void 0), o([e({
  type: Boolean,
  nonNullable: !0
})], s.prototype, "temporaryLayerIndicators", void 0), s = o([r("esri.widgets.LayerList.LayerListVisibleElements")], s);
const l = s;
export { l as default };