/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../core/Collection.js";
import { deprecatedProperty as i } from "../core/deprecate.js";
import { IdentifiableMixin as o } from "../core/Identifiable.js";
import s from "../core/Logger.js";
import l from "../core/ReactiveMap.js";
import { on as r, initial as a } from "../core/reactiveUtils.js";
import { property as n } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as d } from "../core/accessorSupport/decorators/subclass.js";
import c from "./Widget.js";
import { css as p } from "./LayerList/css.js";
import m from "./LayerList/LayerListItem.js";
import h from "./LayerList/LayerListViewModel.js";
import u from "./LayerList/LayerListVisibleElements.js";
import y from "./LayerList/ListItem.js";
import { minDragEnabledItems as g, minFilterItems as f, getItem as L, getLayerType as _, sortLayersToIds as v, sortChildLayersToIds as I } from "./LayerList/support/layerListUtils.js";
import { loadCalciteComponents as b } from "./support/componentsUtils.js";
import { globalCss as w } from "./support/globalCss.js";
import { calciteListFilterProps as E } from "./support/listUtils.js";
import { setFocus as C } from "./support/widgetUtils.js";
import { messageBundle as F } from "./support/decorators/messageBundle.js";
import { vmEvent as M } from "./support/decorators/vmEvent.js";
import { tsx as O } from "./support/jsxFactory.js";
const T = t.ofType(y);
let j = class extends o(c) {
  constructor(e, i) {
    super(e, i), this._activeItem = null, this._tooltipReferenceMap = new l(), this._focusRootFlowItem = !1, this._focusPanelFlowItem = !1, this._focusLayerFlowItem = null, this._layerListMap = new l(), this._lastDragDetail = null, this._selectedDragItemLayerUid = null, this._rootGroupUid = `operational-${this.uid}`, this._openedLayersController = null, this.catalogLayerList = null, this.catalogOptions = null, this.collapsed = !1, this.dragEnabled = !1, this.filterPlaceholder = "", this.filterText = "", this.headingLevel = 2, this.knowledgeGraphOptions = null, this.layerTablesEnabled = new t(["knowledge-graph"]), this.listItemCanGiveFunction = null, this.listItemCanReceiveFunction = null, this.mapImageOptions = null, this.messages = null, this.messagesCommon = null, this.minDragEnabledItems = g, this.minFilterItems = f, this.openedLayers = new t(), this.openedLayerLists = new t(), this.selectedItems = new T(), this.selectionMode = "none", this.tableList = null, this.tileOptions = null, this.viewModel = new h(), this.visibilityAppearance = "default", this.visibleElements = new u(), this._canMove = ({
      dragEl: e,
      fromEl: t,
      toEl: i
    }, o) => {
      const s = "pull" === o ? this.listItemCanGiveFunction : this.listItemCanReceiveFunction,
        l = L(e);
      if (!l?.sortable) return !1;
      const r = L(t),
        a = _(t),
        n = L(i),
        d = _(i),
        c = !!a && !!d && a === d,
        p = {
          selected: l,
          from: r,
          to: n
        },
        m = t.group,
        h = i.group,
        u = r?.layer?.type ?? "",
        y = n?.layer?.type ?? "",
        g = new Set(["map-image", "catalog", "knowledge-graph"]),
        f = "sublayer";
      return m && h && "function" == typeof s ? s.call(null, p) : c && !g.has(u) && !g.has(y) && l?.layer?.type !== f;
    }, this._onCatalogOpen = e => {
      this.openedLayers.push(e.layer.parent), this._focusLayerFlowItem = e.layer.uid;
    }, this._onTablesOpen = e => {
      this.openedLayers.push(e.layer), this._focusLayerFlowItem = e.layer.uid;
    }, this._onPanelOpen = () => {
      this._focusPanelFlowItem = !0;
    }, this._onSelectedDragItemLayerUidChange = e => {
      this._selectedDragItemLayerUid = e;
    }, this._onTooltipReferenceChange = (e, t) => {
      t ? this._tooltipReferenceMap.set(e, t) : this._tooltipReferenceMap.delete(e);
    }, this._onTriggerAction = (e, t) => {
      this.triggerAction(e, t);
    }, this._clearActiveItem = () => {
      this._activeItem = null;
    }, this._setActiveItem = e => {
      if ("default" !== this.visibilityAppearance) return;
      const t = Array.from(e.composedPath()).find(e => e.classList?.contains(p.item));
      this._activeItem = L(t);
    }, this._onCalciteListOrderChange = e => {
      const {
          _lastDragDetail: t
        } = this,
        {
          toEl: i,
          fromEl: o,
          dragEl: s,
          newIndex: l
        } = e;
      if (o && i && !(t?.newIndex === l && t?.dragEl === s && t?.toEl === i && t?.fromEl === o)) if (this._lastDragDetail = e, o !== i) this._moveLayerFromChildList({
        toEl: i,
        fromEl: o,
        dragEl: s,
        newIndex: l
      });else {
        const e = Array.from(o.children).filter(e => e?.matches("calcite-list-item")).map(e => e.value);
        this._sortLayers(o, e);
      }
    };
  }
  initialize() {
    this.addHandles(r(() => this.openedLayers, "change", () => this._handleOpenedLayersChange(), a));
  }
  loadDependencies() {
    return b({
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js"),
      flow: () => import("@esri/calcite-components/dist/components/calcite-flow.js"),
      "flow-item": () => import("@esri/calcite-components/dist/components/calcite-flow-item.js"),
      list: () => import("@esri/calcite-components/dist/components/calcite-list.js"),
      notice: () => import("@esri/calcite-components/dist/components/calcite-notice.js"),
      tooltip: () => import("@esri/calcite-components/dist/components/calcite-tooltip.js")
    });
  }
  destroy() {
    this._destroyOpenedLayerLists(), this._tooltipReferenceMap.clear();
  }
  get _totalItems() {
    return this.viewModel.operationalItems.flatten(e => e.children.filter(e => "catalog-dynamic-group" !== e.layer.type)).length;
  }
  get _dragEnabled() {
    return this._totalItems >= this.minDragEnabledItems && this.dragEnabled;
  }
  get _filterEnabled() {
    return this._totalItems >= this.minFilterItems && this.visibleElements.filter;
  }
  get _renderedOpenLayerFlowItems() {
    return this.openedLayers.toArray().map(e => this._renderLayerFlowItem(e));
  }
  get icon() {
    return "layers";
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(e) {
    this._overrideIfSome("label", e);
  }
  get listItemCreatedFunction() {
    return this.viewModel.listItemCreatedFunction;
  }
  set listItemCreatedFunction(e) {
    this.viewModel.listItemCreatedFunction = e;
  }
  get multipleSelectionEnabled() {
    return i(s.getLogger(this), "multipleSelectionEnabled", {
      replacement: "selectionMode",
      version: "4.29",
      warnOnce: !0
    }), "multiple" === this.selectionMode;
  }
  set multipleSelectionEnabled(e) {
    i(s.getLogger(this), "multipleSelectionEnabled", {
      replacement: "selectionMode",
      version: "4.29",
      warnOnce: !0
    }), this.selectionMode = e ? "multiple" : this.selectionEnabled ? "single" : "none";
  }
  get operationalItems() {
    return this.viewModel.operationalItems;
  }
  set operationalItems(e) {
    this.viewModel.operationalItems = e;
  }
  get selectionEnabled() {
    return i(s.getLogger(this), "selectionEnabled", {
      replacement: "selectionMode, dragEnabled",
      version: "4.29",
      warnOnce: !0
    }), "none" !== this.selectionMode && this.dragEnabled;
  }
  set selectionEnabled(e) {
    i(s.getLogger(this), "selectionEnabled", {
      replacement: "selectionMode, dragEnabled",
      version: "4.29",
      warnOnce: !0
    }), this.dragEnabled = e, this.selectionMode = e ? this.multipleSelectionEnabled ? "multiple" : "single" : "none";
  }
  get view() {
    return this.viewModel.view;
  }
  set view(e) {
    this.viewModel.view = e;
  }
  triggerAction(e, t) {
    return this.viewModel.triggerAction(e, t);
  }
  render() {
    const e = this.viewModel?.state,
      t = {
        [w.hidden]: "loading" === e,
        [w.disabled]: "disabled" === e
      },
      i = this.operationalItems?.filter(e => !e.hidden && (this.visibleElements.errors || !e.error));
    return O("div", {
      class: this.classes(p.base, w.widget, w.panel, t)
    }, i?.length ? [this._renderItemTooltips(i), this._renderItems(i)] : this._renderNoItems());
  }
  async _createFlowList(e, t) {
    const {
        _layerListMap: i
      } = this,
      o = i.get(e);
    if (o) return o;
    const s = "catalog" === e.type ? await this._createCatalogLayerList(e) : await this._createTableList(e);
    return t.aborted || i.set(e, s), s;
  }
  async _handleOpenedLayersChange() {
    const {
      _layerListMap: e,
      openedLayers: t,
      openedLayerLists: i
    } = this;
    this._openedLayersController?.abort();
    const o = new AbortController(),
      {
        signal: s
      } = o;
    this._openedLayersController = o, e.forEach((i, o) => {
      t.includes(o) || (i.destroy(), e.delete(o));
    });
    const l = await Promise.all(t.map(e => this._createFlowList(e, s)));
    if (s.aborted) return;
    i.removeAll(), i.addMany(l);
    const r = i.at(-1);
    r ? "catalogLayer" in r ? (this._set("catalogLayerList", r), this._set("tableList", null)) : (this._set("catalogLayerList", null), this._set("tableList", r)) : (this._set("catalogLayerList", null), this._set("tableList", null));
  }
  _destroyOpenedLayerLists() {
    this.openedLayerLists.destroyAll(), this.openedLayers.removeAll(), this._layerListMap.clear();
  }
  _renderItemTooltip(e) {
    const {
      _tooltipReferenceMap: t,
      messages: i
    } = this;
    return e ? O("calcite-tooltip", {
      key: `tooltip-${e.layer.uid}`,
      referenceElement: t.get(e.layer.uid)
    }, i.layerIncompatibleTooltip) : null;
  }
  _renderItemTooltipNodes(e) {
    return e.incompatible ? this._renderItemTooltip(e) : e.children?.filter(e => !e.hidden).toArray().map(e => this._renderItemTooltipNodes(e));
  }
  _renderItemTooltips(e) {
    return e?.toArray().map(e => this._renderItemTooltipNodes(e));
  }
  _renderNoItemsMessage() {
    return O("div", {
      slot: "message"
    }, this.messages.noItemsToDisplay);
  }
  _renderNoItems() {
    return O("div", {
      class: p.itemMessage,
      key: "esri-layer-list__no-items"
    }, O("calcite-notice", {
      icon: "information",
      kind: "info",
      open: !0,
      width: "full"
    }, this._renderNoItemsMessage()));
  }
  async _createCatalogLayerList(e) {
    const {
        default: t
      } = await import("./CatalogLayerList.js"),
      {
        headingLevel: i,
        catalogOptions: o,
        view: s,
        filterPlaceholder: l,
        listItemCreatedFunction: r,
        minFilterItems: a,
        selectionMode: n,
        visibilityAppearance: d,
        _onCatalogOpen: c,
        _onTablesOpen: p,
        layerTablesEnabled: m
      } = this;
    return new t({
      headingLevel: i,
      view: s,
      filterPlaceholder: l,
      listItemCreatedFunction: r,
      minFilterItems: a,
      selectionMode: n,
      visibilityAppearance: d,
      ...o,
      catalogLayer: e,
      layerTablesEnabled: m,
      onCatalogOpen: c,
      onTablesOpen: p
    });
  }
  _getTableListParams(e) {
    switch (e.type) {
      case "knowledge-graph":
        return {
          ...this.knowledgeGraphOptions,
          tables: e.tables
        };
      case "map-image":
        return {
          ...this.mapImageOptions,
          tables: e.subtables
        };
      case "tile":
        return {
          ...this.tileOptions,
          tables: e.subtables
        };
      default:
        return null;
    }
  }
  async _createTableList(e) {
    const {
        default: t
      } = await import("./TableList.js"),
      {
        headingLevel: i,
        selectionMode: o,
        dragEnabled: s
      } = this;
    return new t({
      headingLevel: i,
      selectionMode: o,
      dragEnabled: s,
      ...this._getTableListParams(e)
    });
  }
  _renderLayerFlowItem(e) {
    const {
        messages: t,
        openedLayers: i
      } = this,
      o = e.title || this.messages.untitledLayer;
    return O("calcite-flow-item", {
      afterCreate: this._focusLayerFlowItemNode,
      afterUpdate: this._focusLayerFlowItemNode,
      bind: this,
      "data-layer-uid": e.uid,
      description: o,
      heading: t["catalog" === e.type ? "catalogLayers" : "tables"],
      headingLevel: this.headingLevel,
      key: `flow-layer-list-${e.uid}`,
      onCalciteFlowItemBack: e => {
        e.preventDefault(), i.pop();
        const t = i.at(-1);
        t ? this._focusLayerFlowItem = t.uid : this._focusRootFlowItem = !0;
      }
    }, this._layerListMap.get(e)?.render());
  }
  _renderPanelFlowItems(e) {
    return e ? e.flatten(e => e.children).filter(({
      hidden: e,
      panel: t
    }) => !e && t?.open && !t.disabled && t.flowEnabled).toArray().map(({
      title: e,
      panel: t
    }) => {
      const i = () => this._handlePanelFlowItemBack(t);
      return O("calcite-flow-item", {
        afterCreate: this._focusPanelFlowItemNode,
        afterUpdate: this._focusPanelFlowItemNode,
        bind: this,
        description: e,
        heading: t.title,
        headingLevel: this.headingLevel,
        key: `flow-panel-${t.uid}`,
        onCalciteFlowItemBack: e => {
          e.preventDefault(), i();
        }
      }, t.render(), O("calcite-button", {
        appearance: "transparent",
        onclick: i,
        slot: "footer-actions",
        width: "full"
      }, this.messagesCommon.back));
    }) : [];
  }
  _handlePanelFlowItemBack(e) {
    e.open = !1, this._focusRootFlowItem = !0;
  }
  _focusRootFlowItemNode(e) {
    this._focusRootFlowItem && (this._focusRootFlowItem = !1, C(e));
  }
  _focusLayerFlowItemNode(e) {
    this._focusLayerFlowItem === e.dataset.layerUid && (this._focusLayerFlowItem = null, C(e));
  }
  _focusPanelFlowItemNode(e) {
    this._focusPanelFlowItem && (this._focusPanelFlowItem = !1, C(e));
  }
  _renderItems(e) {
    const {
        visible: t,
        collapsed: i,
        _filterEnabled: o,
        _rootGroupUid: s,
        visibleElements: {
          closeButton: l,
          collapseButton: r,
          heading: a,
          flow: n
        },
        _dragEnabled: d,
        selectionMode: c,
        filterText: m,
        filterPlaceholder: h,
        messages: u
      } = this,
      y = [O("calcite-flow-item", {
        afterCreate: this._focusRootFlowItemNode,
        afterUpdate: this._focusRootFlowItemNode,
        bind: this,
        closable: l,
        closed: !t,
        collapsed: i,
        collapsible: r,
        heading: a ? u.widgetLabel : void 0,
        headingLevel: this.headingLevel,
        key: "root-flow-item",
        onCalciteFlowItemClose: () => this.visible = !1
      }, O("calcite-list", {
        afterCreate: e => {
          e.addEventListener("focusin", this._setActiveItem), e.addEventListener("focusout", this._clearActiveItem);
        },
        afterRemoved: e => {
          e.removeEventListener("focusin", this._setActiveItem), e.removeEventListener("focusout", this._clearActiveItem);
        },
        canPull: e => this._canMove(e, "pull"),
        canPut: e => this._canMove(e, "put"),
        "data-layer-type": s,
        dragEnabled: d,
        filterEnabled: o,
        filterPlaceholder: h,
        filterProps: E,
        filterText: o ? m : "",
        group: s,
        key: "root-list",
        label: u.widgetLabel,
        onmouseleave: this._clearActiveItem,
        onmouseover: this._setActiveItem,
        selectionAppearance: "border",
        selectionMode: c,
        onCalciteListChange: e => this._handleCalciteListChange(e),
        onCalciteListDragEnd: e => this._handleCalciteListDragEnd(e.detail),
        onCalciteListFilter: e => this.filterText = e.target?.filterText ?? "",
        onCalciteListOrderChange: e => this._onCalciteListOrderChange(e.detail)
      }, e?.toArray().map(e => this._renderItem(e)), e?.length && o ? O("div", {
        class: p.filterNoResults,
        slot: "filter-no-results"
      }, O("calcite-notice", {
        kind: "info",
        open: !0,
        width: "full"
      }, this._renderNoItemsMessage())) : null)), this._renderPanelFlowItems(e), this._renderedOpenLayerFlowItems];
    return t ? n ? O("calcite-flow", {
      key: "root-flow"
    }, y) : y : null;
  }
  _renderItem(e, t, i) {
    return O(m, {
      activeItem: this._activeItem,
      canMove: this._canMove,
      css: p,
      dragEnabled: this.dragEnabled,
      item: e,
      key: `layerListItem-${e.layer.uid}`,
      layerTablesEnabled: this.layerTablesEnabled,
      listModeDisabled: this.viewModel.listModeDisabled,
      messages: this.messages,
      messagesCommon: this.messagesCommon,
      parent: t,
      parentTitles: i,
      rootGroupUid: this._rootGroupUid,
      selectedDragItemLayerUid: this._selectedDragItemLayerUid,
      selectedItems: this.selectedItems,
      selectionMode: this.selectionMode,
      visibilityAppearance: this.visibilityAppearance,
      visibleElements: this.visibleElements,
      onAction: this._onTriggerAction,
      onCatalogOpen: this._onCatalogOpen,
      onPanelOpen: this._onPanelOpen,
      onSelectedDragItemLayerUidChange: this._onSelectedDragItemLayerUidChange,
      onTablesOpen: this._onTablesOpen,
      onTooltipReferenceChange: this._onTooltipReferenceChange
    });
  }
  _moveLayerFromChildList({
    toEl: e,
    fromEl: t,
    dragEl: i,
    newIndex: o
  }) {
    const s = L(i),
      l = L(e),
      r = L(t);
    this.viewModel.moveListItem(s, r, l, o);
  }
  _handleCalciteListDragEnd(e) {
    const {
      fromEl: t,
      dragEl: i,
      oldIndex: o
    } = e;
    t.insertBefore(i, t.children[o]);
  }
  _sortLayers(e, t) {
    if (e) if (e.group === this._rootGroupUid) v(this.view?.map?.layers, t);else {
      const i = L(e);
      if (!i) return;
      I(i, t);
    }
  }
  _handleCalciteListChange(e) {
    const {
      selectionMode: t,
      selectedItems: i
    } = this;
    if ("none" === t) return;
    const o = e.target.selectedItems.map(e => L(e)).filter(Boolean);
    i.removeAll(), i.addMany(o);
  }
};
e([n()], j.prototype, "_activeItem", void 0), e([n()], j.prototype, "_tooltipReferenceMap", void 0), e([n()], j.prototype, "_focusRootFlowItem", void 0), e([n()], j.prototype, "_focusPanelFlowItem", void 0), e([n()], j.prototype, "_focusLayerFlowItem", void 0), e([n()], j.prototype, "_layerListMap", void 0), e([n()], j.prototype, "_totalItems", null), e([n()], j.prototype, "_dragEnabled", null), e([n()], j.prototype, "_filterEnabled", null), e([n()], j.prototype, "_renderedOpenLayerFlowItems", null), e([n({
  readOnly: !0
})], j.prototype, "catalogLayerList", void 0), e([n()], j.prototype, "catalogOptions", void 0), e([n()], j.prototype, "collapsed", void 0), e([n()], j.prototype, "dragEnabled", void 0), e([n()], j.prototype, "filterPlaceholder", void 0), e([n()], j.prototype, "filterText", void 0), e([n()], j.prototype, "headingLevel", void 0), e([n()], j.prototype, "icon", null), e([n()], j.prototype, "knowledgeGraphOptions", void 0), e([n()], j.prototype, "label", null), e([n()], j.prototype, "layerTablesEnabled", void 0), e([n()], j.prototype, "listItemCanGiveFunction", void 0), e([n()], j.prototype, "listItemCanReceiveFunction", void 0), e([n()], j.prototype, "listItemCreatedFunction", null), e([n()], j.prototype, "mapImageOptions", void 0), e([n(), F("esri/widgets/LayerList/t9n/LayerList")], j.prototype, "messages", void 0), e([n(), F("esri/t9n/common")], j.prototype, "messagesCommon", void 0), e([n()], j.prototype, "minDragEnabledItems", void 0), e([n()], j.prototype, "minFilterItems", void 0), e([n()], j.prototype, "multipleSelectionEnabled", null), e([n({
  readOnly: !0
})], j.prototype, "openedLayers", void 0), e([n({
  readOnly: !0
})], j.prototype, "openedLayerLists", void 0), e([n()], j.prototype, "operationalItems", null), e([n()], j.prototype, "selectionEnabled", null), e([n()], j.prototype, "selectedItems", void 0), e([n()], j.prototype, "selectionMode", void 0), e([n({
  readOnly: !0
})], j.prototype, "tableList", void 0), e([n()], j.prototype, "tileOptions", void 0), e([n()], j.prototype, "view", null), e([M("trigger-action"), n({
  type: h
})], j.prototype, "viewModel", void 0), e([n()], j.prototype, "visibilityAppearance", void 0), e([n({
  type: u,
  nonNullable: !0
})], j.prototype, "visibleElements", void 0), j = e([d("esri.widgets.LayerList")], j);
const A = j;
export { A as default };