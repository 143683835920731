/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const t = "esri-layer-list",
  i = `${t}__item`,
  e = {
    base: t,
    actionMenu: `${t}__action-menu`,
    actionGroup: `${t}__action-group`,
    filterNoResults: `${t}__filter-no-results`,
    item: i,
    itemActive: `${i}--active`,
    itemContent: `${i}-content`,
    itemContentBottom: `${i}-content-bottom`,
    itemMessage: `${i}-message`,
    itemInvisible: `${i}--invisible`,
    itemActionIcon: `${i}-action-icon`,
    itemActionImage: `${i}-action-image`,
    itemTemporaryIcon: `${i}-temporary-icon`,
    itemTableIcon: `${i}-table-icon`,
    itemCatalogIcon: `${i}-catalog-icon`,
    statusIndicator: `${t}__status-indicator`,
    publishing: `${t}__publishing`,
    updating: `${t}__updating`,
    connectionStatus: `${t}__connection-status`,
    connectionStatusConnected: `${t}__connection-status--connected`,
    visibleToggle: `${t}__visible-toggle`,
    visibleIcon: `${t}__visible-icon`
  };
export { e as css };