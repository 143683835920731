import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-links-directory',
  templateUrl: './links-directory.component.html',
  styleUrls: ['./links-directory.component.scss'],
})
export class LinksDirectoryComponent implements OnInit, OnDestroy {
  private httpClient = inject(HttpClient);

  links: any;
  subscription: Subscription = new Subscription();

  //Cast ScanFix(NCS) for rule type: Always unsubscribe events in Angular onDestroy method
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  
  constructor() {}

  ngOnInit(): void {
    this.subscription = this.httpClient.get<any>('/assets/links.json').subscribe((data) => {
      this.links = data.links;
    });
  }
}
