/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const n = {
    hide: "hide",
    hideChildren: "hide-children"
  },
  i = 10,
  e = 2;
function t({
  exclusive: n,
  visible: i,
  visibilityAppearance: e
}) {
  const t = "checkbox" === e;
  return i ? n ? "circle-f" : t ? "check-square-f" : "view-visible" : n ? "circle" : t ? "square" : "view-hide";
}
function r({
  connectionStatus: n,
  publishing: i
}) {
  return n ? "connected" === n ? "beacon" : "offline" : i ? "square" : "bullet-point";
}
function l(i) {
  return i?.listMode === n.hideChildren;
}
function u(n) {
  return n?.listMode ?? void 0;
}
function o(n) {
  return null != n && "minScale" in n && null != n.minScale ? n.minScale : void 0;
}
function c(n) {
  return null != n && "maxScale" in n && null != n.maxScale ? n.maxScale : void 0;
}
function a(n) {
  if (!n) return "inherited";
  const i = m(v(n) ? n.layer : n);
  return null != i ? i ? "independent" : "inherited" : "visibilityMode" in n && null != n.visibilityMode ? n.visibilityMode : "independent";
}
function s(n) {
  if (n && (!("type" in n) || "wmts" !== n.type)) return "sublayers" in n ? "sublayers" : "layers" in n ? "layers" : void 0;
}
function d(n) {
  const i = (n?.layer && v(n.layer) ? n.layer.layer : void 0) ?? n?.layer;
  return !!i && "catalog" !== i.type && (x(i) ?? !0);
}
function y(i) {
  return u(i) !== n.hide;
}
function f(n, i) {
  if (!n || null == i || isNaN(i)) return !1;
  const e = o(n),
    t = c(n),
    r = null != e && !isNaN(e) && e > 0 && i > e,
    l = null != t && !isNaN(t) && t > 0 && i < t;
  return r || l;
}
function p(n, i) {
  n?.sort((n, e) => {
    const t = "uid" in n ? i.indexOf(n.uid) : -1,
      r = "uid" in e ? i.indexOf(e.uid) : -1;
    return t > r ? -1 : t < r ? 1 : 0;
  });
}
function b(n, i) {
  const e = n?.layer;
  if (!e) return;
  const t = s(e);
  if (!t) return;
  let r;
  "layers" === t && "layers" in e ? r = e.layers : "sublayers" === t && "sublayers" in e && (r = e.sublayers), p(r, i);
}
function h(n) {
  const i = n?.layer;
  return i && "layers" in i ? i.layers : null;
}
function v(n) {
  return null != n && "layer" in n && null != n.layer;
}
function m(n) {
  const i = S(n);
  return null != i && "supportsSublayerVisibility" in i ? i.supportsSublayerVisibility : void 0;
}
function x(n) {
  const i = S(n);
  return null != i && "supportsDynamicLayers" in i ? i.supportsDynamicLayers : void 0;
}
function S(n) {
  return n && "capabilities" in n && null != n.capabilities && "exportMap" in n.capabilities ? n.capabilities.exportMap : void 0;
}
function M(n) {
  return n?.["data-item"];
}
function g(n) {
  return n?.getAttribute("data-layer-type");
}
function N({
  children: n,
  error: i,
  incompatible: e
}) {
  return !!n?.filter(n => !n.hidden).length && !i && !e;
}
function q(n) {
  for (const i of n) for (const n of i) if ("button" === n.type || "toggle" === n.type) return n;
}
export { l as areChildrenHidden, y as canDisplayLayer, d as canSortSublayers, u as findLayerListMode, c as findLayerMaxScale, o as findLayerMinScale, a as findLayerVisibilityMode, M as getItem, h as getItemLayers, g as getLayerType, s as getNormalizedChildLayerProperty, q as getSingleAction, r as getStatusIcon, t as getVisibilityIcon, f as isLayerOutsideScaleRange, v as isSublayer, N as listItemHasChildren, e as minDragEnabledItems, i as minFilterItems, b as sortChildLayersToIds, p as sortLayersToIds };