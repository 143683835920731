import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, OnChanges, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserInfo } from 'src/app/interfaces/user-info';
import { StylerService } from 'src/app/services/styler/styler.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  private styler: StylerService;
  private router = inject(Router);
  private store = inject(Store);

  footerHeight = 0;
  @Input() showInstallLink = false;
  @Output() installApp = new EventEmitter();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;

  }

  ngOnInit() {
    
  }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.showInstallLink = JSON.parse(localStorage.getItem('showInstallPromt'));
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  support() {
    // Logic below maybe need in the header component so commenting it out for now.
    // try {
    //   const { user } = this.store.selectSnapshot(store => store.AppState.userInfo) as UserInfo;
    //   let url;
    //   switch (user.role) {
    //     case 'sup':
    //       url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/ERA-AXIS.aspx')
    //       break;
    //     case 'eo':
    //       url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/tutorials.aspx')
    //       break;
    //     case 'cc':
    //     default:
    //       url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/ERA-Tutorials%282%29.aspx')
    //       break;
    //   }
    //   window.open(url.toString(), '_blank');
    // } catch {
    //   const url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/Frequently-Asked-Questions.aspx')
    //   window.open(url.toString(), '_blank');
    // }
    let url = 'https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/Frequently-Asked-Questions.aspx'
    window.open(url.toString(), '_blank');

  }
  legal() {     
      let url = 'https://www.coned.com/en/terms-and-conditions'
      window.open(url.toString(), '_blank');
  }
  contactus() {     
      let url = 'https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/Contact-Us.aspx'
      window.open(url.toString(), '_blank');
  }
  install() {
    this.installApp.emit(true);
  }
}
