/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import { isSome as i } from "../../core/arrayUtils.js";
import s from "../../core/Collection.js";
import { IdentifiableMixin as r } from "../../core/Identifiable.js";
import { watch as l, initial as o } from "../../core/reactiveUtils.js";
import { property as n } from "../../core/accessorSupport/decorators/property.js";
import { cast as a } from "../../core/accessorSupport/decorators/cast.js";
import "../../core/has.js";
import { subclass as h } from "../../core/accessorSupport/decorators/subclass.js";
import { get as d } from "../../core/accessorSupport/get.js";
import p from "../../support/actions/ActionBase.js";
import c from "../../support/actions/ActionButton.js";
import u from "../../support/actions/ActionSlider.js";
import y from "../../support/actions/ActionToggle.js";
import b from "./ListItemPanel.js";
import { isLayerOutsideScaleRange as m, findLayerVisibilityMode as v, findLayerListMode as f, canDisplayLayer as C, getNormalizedChildLayerProperty as g } from "./support/layerListUtils.js";
var w;
const S = s.ofType({
    key: "type",
    defaultKeyValue: "button",
    base: p,
    typeMap: {
      button: c,
      toggle: y,
      slider: u
    }
  }),
  _ = s.ofType(S),
  M = "layer",
  P = "child-list-mode",
  O = "hide";
let I = w = class extends r(t) {
  constructor(e) {
    super(e), this.actionsSections = new _(), this.actionsOpen = !1, this.checkPublishStatusEnabled = !1, this.children = new (s.ofType(w))(), this.childrenSortable = !0, this.hidden = !1, this.layer = null, this.listItemCreatedFunction = null, this.listModeDisabled = !1, this.open = !1, this.panel = null, this.parent = null, this.view = null;
  }
  initialize() {
    if (this.addHandles([l(() => [this.layer, this.layer?.listMode, this.listModeDisabled], () => this._watchLayerProperties(this.layer), o), l(() => this.checkPublishStatusEnabled, e => this._updateChildrenPublishing(e), o), l(() => this.view, e => this._updateChildrenView(e), o), l(() => this.panel, e => this._setListItemOnPanel(e), o)]), "function" == typeof this.listItemCreatedFunction) {
      const e = {
        item: this
      };
      this.listItemCreatedFunction.call(null, e);
    }
  }
  destroy() {
    this.panel?.destroy(), this.children.destroyAll(), this.view = null;
  }
  get connectionStatus() {
    const {
      layerView: e,
      publishing: t
    } = this;
    if (!t && e && "connectionStatus" in e) return e.connectionStatus;
  }
  get error() {
    return this.layer?.loadError;
  }
  get incompatible() {
    const {
      layerView: e
    } = this;
    return !(!e || !("spatialReferenceSupported" in e)) && !e.spatialReferenceSupported;
  }
  get layerView() {
    const {
      layer: e,
      view: t
    } = this;
    if (!e || !t || "sublayer" === e.type) return null;
    const i = "subtype-sublayer" === e.type ? e.parent : e;
    return t.allLayerViews.find(e => e.layer === i) ?? null;
  }
  castPanel(e) {
    return this.panel?.open && !e.hasOwnProperty("open") && (e.open = !0), e ? new b(e) : null;
  }
  get sortable() {
    return "knowledge-graph-sublayer" !== this.layer?.type && this._get("sortable");
  }
  set sortable(e) {
    this._set("sortable", e);
  }
  get title() {
    const e = d(this, "layer.layer");
    return (!e || e && d(this, "layer.layer.loaded")) && d(this, "layer.title") || d(this, "layer.attributes.title") || "";
  }
  set title(e) {
    this._override("title", e);
  }
  get publishing() {
    const {
      layer: e,
      checkPublishStatusEnabled: t
    } = this;
    return t && e && "publishingInfo" in e && "publishing" === e.publishingInfo?.status;
  }
  get updating() {
    const {
      layerView: e,
      connectionStatus: t,
      layer: i,
      publishing: s
    } = this;
    return !s && !t && (e ? e.updating : "loading" === i?.loadStatus || !1);
  }
  get visible() {
    return this.layer?.visible;
  }
  set visible(e) {
    const t = this.layer;
    t && (t.visible = e);
  }
  get visibleAtCurrentScale() {
    return this.layerView?.visibleAtCurrentScale ?? !m(this.layer, this.view?.scale);
  }
  get visibleAtCurrentTimeExtent() {
    return this.layerView?.visibleAtCurrentTimeExtent ?? !0;
  }
  get visibilityMode() {
    return v(this.layer);
  }
  clone() {
    return new w({
      actionsSections: this.actionsSections.clone(),
      actionsOpen: this.actionsOpen,
      checkPublishStatusEnabled: this.checkPublishStatusEnabled,
      children: this.children.clone(),
      childrenSortable: this.childrenSortable,
      hidden: this.hidden,
      layer: this.layer,
      listItemCreatedFunction: this.listItemCreatedFunction,
      listModeDisabled: this.listModeDisabled,
      open: this.open,
      panel: this.panel,
      parent: this.parent,
      sortable: this.sortable,
      title: this.title,
      view: this.view,
      visible: this.visible
    });
  }
  _setListItemOnPanel(e) {
    e && (e.listItem = this);
  }
  _updateChildrenPublishing(e) {
    const t = this.children;
    t && t.forEach(t => t.checkPublishStatusEnabled = e);
  }
  _updateChildrenView(e) {
    const t = this.children;
    t && t.forEach(t => t.view = e);
  }
  _createChildren(e) {
    const {
        listModeDisabled: t,
        children: i
      } = this,
      s = e.filter(e => !i.some(t => t.layer === e));
    i.addMany(this._createChildItems(s, t));
  }
  _destroyChildren(e) {
    const {
        children: t
      } = this,
      i = t.filter(t => !e.includes(t.layer));
    t.destroyMany(i);
  }
  _sortChildren(e) {
    this.children.sort((t, i) => e.indexOf(i.layer) - e.indexOf(t.layer));
  }
  _destroyAllChildren() {
    this.removeHandles(P), this.children.destroyAll();
  }
  _watchChildLayerListMode(e) {
    this.removeHandles(P), this.listModeDisabled || this.addHandles(e.toArray().map(t => l(() => t.listMode, () => this._compileChildren(e))), P);
  }
  _compileChildren(e) {
    const t = this.listModeDisabled ? e : e?.filter(e => f(e) !== O);
    t?.length ? (this._createChildren(t), this._destroyChildren(t), this._sortChildren(t), this._watchChildLayerListMode(e)) : this._destroyAllChildren();
  }
  _watchSublayerChanges(e) {
    e && this.addHandles(e.on("change", () => this._compileChildren(e)), M);
  }
  _initializeChildLayers(e) {
    this._compileChildren(e), this._watchSublayerChanges(e);
  }
  _createChildItems(e, t) {
    return e.map(e => t || C(e) ? new w({
      layer: e,
      checkPublishStatusEnabled: this.checkPublishStatusEnabled,
      listItemCreatedFunction: this.listItemCreatedFunction,
      listModeDisabled: this.listModeDisabled,
      parent: this,
      view: this.view
    }) : null).filter(i).reverse();
  }
  _watchLayerProperties(e) {
    if (this.removeHandles(M), this.removeHandles(P), !e) return;
    if ("hide-children" === (!this.listModeDisabled && f(e))) return void this.children.destroyAll();
    const t = g(e);
    t && this.addHandles(l(() => e[t], i => {
      e.hasOwnProperty(t) && this._initializeChildLayers(i);
    }, o), M);
  }
};
e([n({
  type: _
})], I.prototype, "actionsSections", void 0), e([n()], I.prototype, "actionsOpen", void 0), e([n()], I.prototype, "checkPublishStatusEnabled", void 0), e([n({
  type: s
})], I.prototype, "children", void 0), e([n()], I.prototype, "childrenSortable", void 0), e([n({
  readOnly: !0
})], I.prototype, "connectionStatus", null), e([n({
  readOnly: !0
})], I.prototype, "error", null), e([n()], I.prototype, "hidden", void 0), e([n({
  readOnly: !0
})], I.prototype, "incompatible", null), e([n()], I.prototype, "layer", void 0), e([n({
  readOnly: !0
})], I.prototype, "layerView", null), e([n()], I.prototype, "listItemCreatedFunction", void 0), e([n({
  nonNullable: !0
})], I.prototype, "listModeDisabled", void 0), e([n()], I.prototype, "open", void 0), e([n({
  type: b
})], I.prototype, "panel", void 0), e([a("panel")], I.prototype, "castPanel", null), e([n()], I.prototype, "parent", void 0), e([n({
  value: !0
})], I.prototype, "sortable", null), e([n()], I.prototype, "title", null), e([n({
  readOnly: !0
})], I.prototype, "publishing", null), e([n({
  readOnly: !0
})], I.prototype, "updating", null), e([n()], I.prototype, "view", void 0), e([n()], I.prototype, "visible", null), e([n({
  readOnly: !0
})], I.prototype, "visibleAtCurrentScale", null), e([n({
  readOnly: !0
})], I.prototype, "visibleAtCurrentTimeExtent", null), e([n({
  readOnly: !0
})], I.prototype, "visibilityMode", null), I = w = e([h("esri.widgets.LayerList.ListItem")], I);
const j = I;
export { j as default };