
<!-- <div class="background-overlay"></div> -->
<div class="row sign-in-row">
  <div class="col-xl-6 col-lg-12 col-md-12 ">
    <h3 class="sign-in-title">Environmental Resources</h3>
    <div class="style-rule">
      
      <p class="sign-in-login-text"><b>Log In</b></p>
      <div class="form-group row">
       
      </div>
      <div class="form-group row">
        <div class="login-nav-btns">
          <ce-button size='large' label="Employee Login" type="primary" (clickEvent)="employeeSignIn()"></ce-button>
        </div>
      </div>
      <div class="form-group row">
        <div class="login-nav-btns">
          <ce-button size='large' label="Contractor Login" type="secondary" (clickEvent)="contractorSignIn()"></ce-button>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-xl-6 col-lg-12 col-md-12 ">
          <a (click)="goToSharePointFAQ()">Need help?</a>
        </div>
      </div>
    </div>
  </div>
</div>
<ce-modal [visible]="isOffline" title="This device is offline">
  <ce-modal-body>
    <p>To effectively service your flush request, please look for service to reconnect.</p>
    <div class="d-flex">
      <ce-button label="Close" type='secondary' (clickEvent)="closeOfflineModal()" size='large' style="padding-right: 15px;"></ce-button>
      <ce-button label="Refresh" type='primary' (clickEvent)="refreshPage()" size='large'></ce-button>
    </div>
  </ce-modal-body>
</ce-modal>